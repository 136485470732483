<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <ul class="breadcrumb">
          <li><a :href="urls.index">BSD</a></li>
          <li class="active">
            Association Track Déchets et Affaires
            <span class="badge">{{ collection.count }}</span>
          </li>
          <li class="pull-right">
            <cte-selectable-statuses
              :current="collection.filters.statuses"
              :available="['mapped', 'unmapped', 'ignored']"
            />
          </li>
        </ul>
      </div>
    </div>

    <cte-alert
      v-if="collection.others.outOfSyncSince"
      type="warning"
      :msg="'La connexion à Track Déchets semble inopérante. La dernière modification prise en compte date du ' + new Date(collection.others.outOfSyncSince.date).toLocaleDateString() + '.'"
    />

    <cte-alert
      v-if="collection.count <= 0"
      type="info"
      msg="Aucun BSD à associer."
    />
    <template v-else>
      <div class="row">
        <div class="col-xs-12">
          <p>
            <input
              type="text"
              class="table-filter form-control"
              placeholder="Recherche"
              value=""
            >
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="table-responsive">
            <table
              id="bsd-mapping-table"
              class="table table-condensed"
            >
              <thead>
                <tr>
                  <th>Numéro Libre</th>
                  <th>Numéro TD</th>
                  <th>Producteur</th>
                  <th>Ville du chantier</th>
                  <th>Matière</th>
                  <th>Poids (T)</th>
                  <th>Statut</th>
                  <th>
                    Association  <i
                      v-if="maxBusinesses !== null"
                      class="fa fa-plus-circle"
                      style="cursor:pointer;"
                      @click="moreBusinesses()"
                    />
                  </th>
                  <th class="table-actions" />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="candidate in collection.entities"
                  :key="candidate.trackDechetsCustomId"
                  :class="[candidate.isIgnored ? 'mapping__ignored' : '', candidate.businessId ? 'mapping__mapped' : '']"
                >
                  <td>{{ candidate.trackDechetsCustomId }}</td>
                  <td class="tracking-number">
                    {{ candidate.trackDechetsReadableId }}
                    <span class="hide">{{ candidate.businessNumber }}</span>
                  </td>
                  <td>{{ candidate.producerName }}</td>
                  <td>{{ candidate.workSiteCity }}</td>
                  <td>{{ candidate.substance }}</td>
                  <td>{{ candidate.weightT }}</td>
                  <td><cte-workflow :states="candidate.states" /></td>
                  <td>
                    <form
                      method="post"
                      style="display: flex; align-items: center;"
                    >
                      <input
                        type="hidden"
                        name="trackDechetsId"
                        :value="candidate.trackDechetsId"
                      >
                      <div style="width: 100px;">
                        <cte-select-business
                          v-if="rerender"
                          :businesses-prop="displayedBusinesses"
                          :selected="candidate.businessId ? candidate.businessId.toString() : null"
                        />
                        <input
                          class="form-control input-sm"
                          name="increment"
                          type="number"
                          min="1"
                          step="1"
                          max="250"
                          required
                          :value="candidate.increment"
                          placeholder="Incrément *"
                          style="width: 100%;"
                        >
                      </div>
                      <button
                        style="margin-left: 3px;"
                        class="btn btn-primary btn-primary btn-lg"
                        title="Associer"
                        type="submit"
                      >
                        <i class="fa fa-check" />
                      </button>
                    </form>
                  </td>
                  <td class="table-actions">
                    <a
                      :href="urls.ignore + '?id=' + candidate.trackDechetsId"
                      :class="['btn btn-default btn-lg', candidate.isIgnored ? 'disabled' : '']"
                      title="Sans affaire"
                    >
                      <i class="fa fa-ban" />
                    </a>

                    <a
                      :href="candidate.trackDechetsViewUrl"
                      target="_blank"
                      class="btn btn-default btn-lg"
                      title="Voir dans trackdechets"
                    >
                      <img
                        width="24"
                        src="/img/trackdechets2.png"
                      >
                    </a>

                    <a
                      v-if="candidate.canBeDeleted"
                      :href="urls.delete + '?id=' + candidate.id"
                      class="btn btn-default btn-lg"
                      data-toggle="confirmation"
                      data-btn-ok-label="Supprimer"
                    >
                      <i class="fa fa-trash" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CteSelectableStatuses from '../components/CteSelectableStatuses';
import CteWorkflow from '../components/CteWorkflow';
import CteSelectBusiness from './CteSelectBusiness';
import CteAlert from '../components/CteAlert';

export default {
    components: {CteAlert, CteSelectBusiness, CteWorkflow, CteSelectableStatuses},
    props: {
        urls: {type: Object, required: true},
        collection: {type: Object, required: true},
        businesses: {type: Array, required: true},
    },
    data() {
        return {
            maxBusinesses: this.collection.filters.statuses.includes('mapped') ? 50 : null,
            rerender: true
        };
    },
    computed: {
        displayedBusinesses() {
            return this.maxBusinesses ? this.businesses.slice(0, this.maxBusinesses) : this.businesses;
        }
    },
    methods: {
        moreBusinesses() {
            this.rerender = false;
            this.maxBusinesses = null;
            this.$nextTick(() => this.rerender = true);
        }
    }
};
</script>
