<template>
  <div class="cte-stock-elements">
    <div class="row">
      <div class="col-xs-12">
        <ul class="breadcrumb">
          <li>Stock</li>
          <li class="active">
            <cte-stock-view
              :refresh-url="urls.changeView"
              :warehouses="warehouses"
              :disabled="selecting"
              :current="'elements_' + warehouseId"
            />
            <span class="badge">{{ stock.elements.length }}</span>
          </li>

          <li class="pull-right shift">
            <cte-selectable-statuses
              :current="stock.filters.statuses"
              :available="['stock', 'out_of_stock_6m', 'out_of_stock_6p']"
            />
          </li>
          <li class="pull-right">
            <div
              v-if="!selecting"
              class="btn-group"
            >
              <a
                v-if="false"
                class="btn btn-primary btn-sm"
                href="#"
                :class="{'disabled': stock.elements.length <= 0}"
                @click.prevent="selectEdit()"
              >
                <i class="fa fa-pencil" />
                <span class="hidden-xs">Editer</span>
              </a>
              <a
                class="btn btn-primary btn-sm"
                href="#"
                :class="{'disabled': stock.elements.length <= 0}"
                @click.prevent="selectDelete()"
              >
                <i class="fa fa-trash" />
                <span class="hidden-xs">Supprimer plus.</span>
              </a>
            </div>
            <template v-else>
              <a
                class="btn btn-sm"
                href="#"
                @click.prevent="cancelSelect()"
              >
                <i class="fa fa-times" />
                <span class="hidden-xs">Annuler</span>
              </a>
              <a
                class="btn btn-primary btn-sm"
                href="#"
                :class="{'disabled': selected.length <= 0}"
                @click.prevent="confirm()"
              >
                <i class="fa fa-check" />
                <span class="hidden-xs">{{ mode === 'edit' ? 'Editer' : 'Supprimer' }}</span>
              </a>
            </template>
          </li>
        </ul>
      </div>
    </div>

    <cte-alert
      v-if="stock.error"
      type="danger"
      :msg="stock.error"
    />

    <cte-alert
      v-if="stock.elements.length <= 0"
      type="info"
      msg="Aucun stock."
    />
    <template v-else>
      <div class="row">
        <div class="col-xs-12">
          <div class="table-responsive">
            <template v-if="isVehicle">
              <table class="table table-condensed">
                <thead>
                  <tr>
                    <th
                      v-if="selecting"
                      class="col-delimiter text-center"
                    />

                    <th>IUS</th>
                    <th>Libellé</th>
                    <th>
                      Affaire <i
                        v-if="maxBusinesses <= defaultMaxBusiness"
                        class="fa fa-plus-circle"
                        style="cursor:pointer;"
                        @click="moreBusinesses()"
                      />
                    </th>
                    <th class="col-delimiter">
                      BSD
                    </th>

                    <th
                      class="col-delimiter text-center"
                      colspan="2"
                    >
                      Chargement <br> Producteur
                    </th>
                    <th
                      class="col-delimiter text-center"
                      style="vertical-align: top"
                    >
                      Entrée <br> stockage Alata
                    </th>
                    <th
                      class="col-delimiter text-center"
                      style="vertical-align: top"
                    >
                      Sortie <br> stockage Alata
                    </th>
                    <th
                      class="col-delimiter text-center"
                      colspan="2"
                    >
                      Déchargement <br> Traitement
                    </th>

                    <th class="table-actions" />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(element, index) in elements"
                    :key="'element_' + element.id"
                    class="cte-stock-elements__row"
                    :class="{'cte-stock-elements__row--saving': saving === element.id,
                             'cte-stock-elements__row--done': !!element.inRecipient,
                             'cte-stock-elements__row--saved': saved === element.id}"
                  >
                    <td
                      v-if="selecting"
                      class="col-delimiter"
                    >
                      <input
                        type="checkbox"
                        @click="toggleSelect(element)"
                      >
                    </td>

                    <td>{{ element.ius }}</td>
                    <td>
                      <span
                        v-if="element.packaging || element.volume"
                        data-toggle="tooltip"
                        :title="elementTooltip(element)"
                      >
                        {{ element.label ? element.label : element.wasteCategory }}
                      </span>
                      <span v-else>{{ element.label ? element.label : element.wasteCategory }}</span>
                    </td>
                    <td>
                      <template v-if="isInPlaceEditable(element)">
                        <select
                          v-if="businesses.length > 0"
                          v-model="element.businessNumber"
                          style="max-width: 100px; font-size: 10px;"
                        >
                          <option />
                          <option
                            v-for="business in businesses"
                            :value="business"
                          >
                            {{ business }}
                          </option>
                        </select>
                      </template>
                      <span v-else>{{ element.businessNumber }}</span>
                    </td>
                    <td class="col-delimiter">
                      <template v-if="isInPlaceEditable(element)">
                        <select
                          v-if="wasteTrackers(element.businessNumber).length > 0"
                          v-model="element.wasteTrackerId"
                          style="max-width: 100px; font-size: 10px;"
                        >
                          <option />
                          <option
                            v-for="wasteTracker in wasteTrackers(element.businessNumber)"
                            :key="'wst' + wasteTracker.id"
                            :value="wasteTracker.id"
                          >
                            {{ wasteTracker.label }}
                          </option>
                        </select>
                      </template>
                      <span v-else>{{ element.wasteTrackerNumber }}</span>
                    </td>

                    <template v-if="element.outProducer">
                      <td>
                        <span
                          data-toggle="tooltip"
                          :title="cargoTooltip(element.outProducer)"
                        >
                          {{ element.outProducer.dateStr }}
                        </span>
                      </td>
                      <td class="col-delimiter">
                        <input
                          v-if="isInPlaceEditable(element) && !element.outProducer.isPlaceAccurate"
                          v-model="element.outProducerPlace"
                          type="text"
                          :tabindex="index*100+3"
                          style="max-width: 100px; font-size: 10px;"
                          @keyup.enter="saveElement(element)"
                        >
                        <span
                          v-else
                          style="font-size: 10px;"
                        >{{ element.outProducerPlace }}</span>
                      </td>
                    </template>
                    <template v-else>
                      <td />
                      <td class="col-delimiter" />
                    </template>

                    <td
                      class="text-center col-delimiter"
                      :class="!element.inWarehouse && element.inRecipient ? 'cte-stock-elements__inactive' : ''"
                    >
                      <span
                        v-if="element.inWarehouse"
                        data-toggle="tooltip"
                        :title="cargoTooltip(element.inWarehouse)"
                      >
                        {{ element.inWarehouse.dateStr }}
                      </span>
                    </td>

                    <td
                      class="text-center col-delimiter"
                      :class="!element.outWarehouse && element.inRecipient ? 'cte-stock-elements__inactive' : ''"
                    >
                      <span
                        v-if="element.outWarehouse"
                        data-toggle="tooltip"
                        :title="cargoTooltip(element.outWarehouse)"
                      >
                        {{ element.outWarehouse.dateStr }}
                      </span>
                    </td>

                    <template v-if="element.inRecipient">
                      <td>
                        <span
                          data-toggle="tooltip"
                          :title="cargoTooltip(element.inRecipient)"
                        >
                          {{ element.inRecipient.dateStr }}
                        </span>
                      </td>
                      <td class="col-delimiter">
                        <input
                          v-if="isInPlaceEditable(element) && !element.inRecipient.isPlaceAccurate"
                          v-model="element.inRecipientPlace"
                          type="text"
                          :tabindex="index*100+4"
                          style="max-width: 100px; font-size: 10px;"
                          @keyup.enter="saveElement(element)"
                        >
                        <span
                          v-else
                          style="font-size: 10px;"
                        >{{ element.inRecipientPlace }}</span>
                      </td>
                    </template>
                    <template v-else>
                      <td />
                      <td class="col-delimiter" />
                    </template>

                    <th class="table-actions">
                      <button
                        v-if="isInPlaceEditable(element)"
                        class="btn btn-primary"
                        @click.prevent="saveElement(element)"
                      >
                        <i class="fa fa-floppy-o" />
                      </button>
                      <a
                        :href="'/stock/edit?from=elements&id=' + element.id"
                        class="btn btn-default"
                        style="margin-left: .5rem;"
                      >
                        <i class="fa fa-pencil" />
                      </a>
                      <a
                        :href="'/stock/delete?from=elements&ids[]=' + element.id"
                        class="btn btn-default"
                        data-toggle="confirmation"
                        data-btn-ok-label="Supprimer"
                        style="margin-left: .5rem;"
                      >
                        <i class="fa fa-trash" />
                      </a>
                    </th>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-else>
              <table class="table table-condensed">
                <thead>
                  <tr>
                    <td
                      v-if="selecting"
                      class="col-delimiter"
                    >
                              &nbsp;
                    </td>

                    <th>IUS</th>
                    <th>Catégorie</th>
                    <th>Libellé</th>
                    <th>Poids / volume</th>
                    <th>
                      Affaire <i
                        v-if="maxBusinesses <= defaultMaxBusiness"
                        class="fa fa-plus-circle"
                        style="cursor:pointer;"
                        @click="moreBusinesses()"
                      />
                    </th>
                    <th class="col-delimiter">
                      BSD
                    </th>

                    <th style="white-space: nowrap;">
                      Entrée
                    </th>
                    <th class="col-delimiter">
                      Provenance
                    </th>

                    <th style="white-space: nowrap;">
                      Sortie
                    </th>
                    <th class="col-delimiter">
                      Destination
                    </th>

                    <th class="table-actions" />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(element, index) in elements"
                    :key="'element_' + element.id"
                    class="cte-stock-elements__row"
                    :class="{'cte-stock-elements__row--saving': saving === element.id,
                             'cte-stock-elements__row--done': element.outWarehouse || element.inRecipient,
                             'cte-stock-elements__row--saved': saved === element.id}"
                  >
                    <td
                      v-if="selecting"
                      class="col-delimiter"
                    >
                      <input
                        type="checkbox"
                        @click="toggleSelect(element)"
                      >
                    </td>

                    <td>{{ element.ius }}</td>
                    <td>
                      <span
                        v-if="element.packaging"
                        data-toggle="tooltip"
                        :title="element.packaging"
                      >
                        {{ element.wasteCategory }}
                      </span>
                      <span v-else>{{ element.wasteCategory }}</span>
                    </td>
                    <td>
                      <input
                        v-model="element.label"
                        type="text"
                        :tabindex="index*100+1"
                        style="width: 180px; font-size: 10px;"
                        @keyup.enter="saveElement(element)"
                      >
                    </td>
                    <td>
                      <input
                        v-model="element.volume"
                        type="text"
                        :tabindex="index*100+2"
                        style="max-width: 50px; font-size: 10px;"
                        @keyup.enter="saveElement(element)"
                      >
                    </td>

                    <td>
                      <select
                        v-if="businesses.length > 0"
                        v-model="element.businessNumber"
                        style="max-width: 100px; font-size: 10px;"
                      >
                        <option />
                        <option
                          v-for="business in businesses"
                          :value="business"
                        >
                          {{ business }}
                        </option>
                      </select>
                    </td>
                    <td class="col-delimiter">
                      <select
                        v-if="wasteTrackers(element.businessNumber).length > 0"
                        v-model="element.wasteTrackerId"
                        style="max-width: 100px; font-size: 10px;"
                      >
                        <option />
                        <option
                          v-for="wasteTracker in wasteTrackers(element.businessNumber)"
                          :key="'wst' + wasteTracker.id"
                          :value="wasteTracker.id"
                        >
                          {{ wasteTracker.label }}
                        </option>
                      </select>
                    </td>

                    <td>
                      <span
                        v-if="element.inWarehouse"
                        data-toggle="tooltip"
                        :title="cargoTooltip(element.inWarehouse)"
                      >
                        {{ element.inWarehouse.dateStr }}
                      </span>
                    </td>
                    <td class="col-delimiter">
                      {{ element.outProducerPlace }}
                    </td>

                    <td>
                      <span
                        v-if="element.outWarehouse"
                        data-toggle="tooltip"
                        :title="cargoTooltip(element.outWarehouse)"
                      >
                        {{ element.outWarehouse.dateStr }}
                      </span>
                      <span
                        v-else-if="element.inRecipient"
                        data-toggle="tooltip"
                        :title="cargoTooltip(element.inRecipient)"
                      >
                        {{ element.inRecipient.dateStr }}
                      </span>
                    </td>

                    <td class="col-delimiter">
                      {{ element.inRecipientPlace }}
                    </td>

                    <th class="table-actions">
                      <button
                        class="btn btn-primary"
                        @click.prevent="saveElement(element)"
                      >
                        <i class="fa fa-floppy-o" />
                      </button>
                      <a
                        :href="'/stock/edit?from=warehouse&id=' + element.id"
                        class="btn btn-default"
                        style="margin-left: .5rem;"
                      >
                        <i class="fa fa-pencil" />
                      </a>
                      <a
                        :href="'/stock/delete?from=warehouse&ids[]=' + element.id"
                        class="btn btn-default"
                        data-toggle="confirmation"
                        data-btn-ok-label="Supprimer"
                        style="margin-left: .5rem;"
                      >
                        <i class="fa fa-trash" />
                      </a>
                    </th>
                  </tr>
                </tbody>
              </table>
            </template>
          </div>
        </div>
      </div>
    </template>

    <form
      ref="selectedForm"
      class="hide"
      method="post"
      :action="mode === 'delete' ? urls.delete : urls.edit"
    >
      <input
        type="hidden"
        name="ids"
        :value="selected.join(',')"
      >
    </form>
  </div>
</template>

<script>
import CteAlert from '../../components/CteAlert.vue';
import CteSelectableStatuses from '../../components/CteSelectableStatuses.vue';
import CteStockView from './CteStockView.vue';
import UI from '../../ui';

const SAVED_BACKGROUND_PERSISTENCE_SEC = 1;
const DEFAULT_MAX_BUSINESS = 180;

export default {
    components: {CteStockView, CteSelectableStatuses, CteAlert},
    props: {
        urls: {required: true},
        stock: {required: true},
        warehouses: {type: Array, required: true},
        warehouseId: {type: Number, required: true},
    },
    data() {
        return {
            elements: this.stock.elements,
            mode: 'edit',
            selected: [],
            selecting: false,
            saving: null,
            saved: null,
            maxBusinesses: DEFAULT_MAX_BUSINESS
        };
    },
    computed: {
        defaultMaxBusiness() {
            return DEFAULT_MAX_BUSINESS;
        },
        isVehicle() {
            return !this.warehouseId;
        },
        businesses() {
            const ids = [...new Set(this.stock.businesses.map(business => business.businessId))];

            return ids.map(id => this.stock.businesses.find(business => business.businessId === id).estimateNumber)
                .sort((a, b) => b.localeCompare(a))
                .slice(0, this.maxBusinesses);
        }
    },
    methods: {
        isInPlaceEditable(element) {
            //only if not unloaded or unloaded recently (30 days)
            return !element.inRecipient || (new Date(element.inRecipient.date.date) > (new Date() - 1000 * 60 * 60 * 24 * 30));
        },
        elementTooltip(element) {
            return [element.packaging, element.volume].filter(info => info).join('<br>');
        },
        cargoTooltip(cargo) {
            return [cargo.dateStr + ' ' + cargo.timeStr, cargo.vehicle, cargo.driver].filter(info => info).join('<br>');
        },
        moreBusinesses() {
            this.maxBusinesses = 5000;
        },
        wasteTrackers(businessNumber) {
            return this.stock.businesses
                .filter(business => business.estimateNumber === businessNumber && business.bsdIncrement)
                .map(business => {
                    return {
                        id: business.bsdId,
                        label: business.estimateNumber + '-' + business.bsdIncrement,
                    };
                });
        },
        selectEdit() {
            this.selecting = true;
            this.mode = 'edit';
        },
        selectDelete() {
            this.selecting = true;
            this.mode = 'delete';
        },
        cancelSelect() {
            this.selected = [];
            this.selecting = false;
        },
        confirm() {
            if (!this.selecting || this.selected.length <= 0) {
                return;
            }

            if (this.mode === 'delete') {
                if (!confirm('Etes vous sûr ?')) {
                    return;
                }
            }

            this.$refs.selectedForm.submit();
        },
        toggleSelect(element) {
            if (!this.selecting) {
                return;
            }

            const index = this.selected.findIndex(id => id === element.id);

            if (index === -1) {
                this.selected.push(element.id);
            } else {
                this.selected.splice(index, 1);
            }
        },
        saveElement(element) {
            const data = {
                id: element.id,
                fromPlace: element.outProducerPlace,
                toPlace: element.inRecipientPlace,
                label: element.label,
                volume: element.volume,
                businessNumber: element.businessNumber,
                wasteTrackerId: element.wasteTrackerId,
            };

            this.saving = data.id;
            fetch(this.urls.saveElement, {
                headers: {'Content-Type': 'application/json'},
                method: 'POST',
                body: JSON.stringify(data)
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error();
                })
                .then(() => {
                    this.saved = data.id;
                    setTimeout(() => this.saved = null, SAVED_BACKGROUND_PERSISTENCE_SEC * 1000);
                })
                .catch(() => UI.error('Erreur lors de la sauvegarde, veuillez réessayer.'))
                .finally(() => this.saving = null);
        }
    }
};
</script>

<style lang="scss">
.cte-stock-elements {
    table {
        font-size: 12px;
    }

    &__inactive {
        background: #dddddd;
    }

    &__row {
        transition: background 1s ease-out;

        input, select {
            border: 1px solid #dddddd;
        }

        &--saving {
            background: #d9edf7;

            input, select {
                background: #d9edf7;
            }
        }

        &--done, &--saved {
            background-color: #dff0d8;

            input, select {
                background: #dff0d8;
            }
        }
    }
}
</style>
